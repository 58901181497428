import React, { Component } from 'react';
import axios from 'axios';
import { Routes, POOLING_INTERVAL_MS } from './config';
import __ from './lang';

export default class SupportChat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            token: document.head.querySelector('meta[name="csrf-token"]').content,
            messages: [],
            inputText: '',

            lang: $('#html').attr('lang')

        };

        console.log($('#html').attr('lang'));

        this.messageBox = React.createRef();

        const that = this;
        this.interval = setInterval(() => {
            let lastMessage = false;
            if (this.state.messages.length) {
                const sortedMessages = [...this.state.messages].sort((a, b) => {
                    if (a.time > b.time) {
                        return 1;
                    }
                    if (a.time < b.time) {
                        return -1;
                    }
                    if (a.time === b.time) {
                        return 0;
                    }
                });
                lastMessage = sortedMessages[sortedMessages.length-1];
            }
            const params = lastMessage ? `/?startTime=${encodeURIComponent(lastMessage.time)}` : '';
            axios.get(Routes.messages + params)
                .then(res => {
                    // TODO удалить потом
                    console.log('req from interval');
                    // Пришедшие сообщения
                    const gotedMessages = res.data;
                    const isNewFromOpponent = that.isNewFromOpponent(gotedMessages);
                    // Существующие сообщения
                    const existedMessages = this.state.messages;

                    const prevMessagesLen = existedMessages.length;

                    // Объект {id: msg, id2: msg2}
                    const newMessagesIdVal = {};

                    // Удаляем дублирующиеся сообщения
                    gotedMessages.forEach(msg => {
                        newMessagesIdVal[msg.id] = msg;
                    });
                    existedMessages.forEach(msg => {
                        newMessagesIdVal[msg.id] = msg;
                    });
                    const newMessages = Object.values(newMessagesIdVal);
                    const newReadedMessages = newMessages.map(msg => {
                        if ((msg.to) || (isNewFromOpponent && msg.from)) {
                            return {...msg, is_read: true};
                        }
                        return {...msg};
                    });

                    const newMessagesLen = newReadedMessages.length;

                    that.setState({
                        messages: newReadedMessages
                    });

                    // if (prevMessagesLen < newMessagesLen) {
                    //     that.scrollToBottom();
                    // }
                });
        }, POOLING_INTERVAL_MS);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.getSortedMessages = this.getSortedMessages.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.removeNotification = this.removeNotification.bind(this);
    }

    showNotification(notification) {
        $('#alert').html("<div class='alert alert-" + notification.type +"'>"+notification.text+"</div>");
        $('#alert').show();
    }

    removeNotification() {
        $('#alert').html("");
    }

    // Get messages
    componentDidMount() {
        const that = this;
        axios.get(Routes.messages)
            .then(res => {
                const messages = res.data;
                that.setState({messages})
            });

        var refreshId = setInterval( function () {
            if ($(".message-container").length) {
                $(document.getElementById('messages')).scrollTop(99999);
                clearInterval(refreshId);
            }
        }, 250);
    }

    onInputChange(event) {
        const text = event.target.value;
        this.setState({inputText: text});
    }


    onFormSubmit(event) {
        const that = this;
        event.preventDefault();
        if (!this.state.inputText) {
            return;
        }
        const text = this.state.inputText;
        this.setState({inputText: ''});
        axios.post(Routes.sendMessage, {
            'messageText': text,
            'lang': that.state.lang
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(args => {
                if (args.data.notification)
                    this.showNotification(args.data.notification);
                else
                    this.removeNotification();
            })
    }

    getSortedMessages() {
        return [...this.state.messages].sort((a, b) => {
            if (a.time > b.time) return 1;
            if (a.time < b.time) return -1;
            if (a.time === b.time) return 0;
        });
    }

    isNewFromOpponent(messages) {
        let isNew = false;
        messages.forEach(msg => {
           if (msg.to) {
               isNew = true;
           }
        });
        return isNew;
    }

    render() {

        const sortedMessages = this.getSortedMessages();

        return (
            <React.Fragment>
                <div className="admin-chat__dm-screen UserChat">
                    <div className="helper">
                        <p>Admin</p>
                        <p>You</p>
                    </div>
                    <div className="messages" id="messages" ref={this.messageBox}>

                        {
                            this.state.messages.length ?
                                sortedMessages.map(message =>{
                                    const isYourMessage = !!message.from;
                                    const isMessageRead = !!message.is_read;
                                    return (
                                        <div
                                            className={`message-container message-container--${isYourMessage ? 'right' : 'left'} ${!isMessageRead ? 'message-container--unread' : ''}`}
                                             key={message.id}
                                        >
                                            <div className={`message ${isYourMessage ? 'message--your' : ''}`}>
                                                {message.text}
                                            </div>
                                        </div>
                                    )
                                } )
                                :
                                ''
                        }

                    </div>
                    <form className="controls" method="POST" onSubmit={this.onFormSubmit}>
                        <input name="_token" value={ this.state.token } type="hidden" />
                        <textarea
                            onChange={this.onInputChange}
                            className="message-field"
                            name="message"
                            placeholder="Enter a message"
                            value={this.state.inputText}
                            autoComplete="off"
                        />
                        <button className="send-button" type="submit"><i className="fas fa-paper-plane"></i></button>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}
